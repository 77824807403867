import React from "react"; 
import { IBGEProvider } from "hooks/useIBGE";
import { AddressProvider } from "hooks/useAddress";
import { useAuth } from "../../hooks/useAuthProvider/useAuth";
import { SignInRegister } from "../../pages/SignInRegister";
const CountriesProvider = React.lazy(() => import('hooks/useCountries'));

export const ProtectedLayout = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return (
      <AddressProvider>
        <IBGEProvider>
          <CountriesProvider>
            <SignInRegister />
          </CountriesProvider>
        </IBGEProvider>
      </AddressProvider>
    );
  }

  return children;
};
