import { EventsTicketsData } from "./types";

export const SetEventInLocalStorage = (event: EventsTicketsData) => {
    localStorage.setItem("EventInCart", JSON.stringify(event));
  }
  
export const GetEventInLocalStorage = ():EventsTicketsData => {
    const event = localStorage.getItem("EventInCart");
    return JSON.parse(event || "{}");
}
  