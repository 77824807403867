import { ToastContainer } from "react-toastify";
import AOS from "aos";
import 'utils/monitoring/datadog/index';

import { ViewportProvider } from "./hooks/useResponsive";
import { MainRoutes } from "./routes/MainRoutes";
import { AuthProvider } from "hooks/useAuthProvider";
import { GlobalStyle } from "styles/global";
import { HelmetProvider } from "react-helmet-async";
import { ModalVerificationPhoneNumber } from "layouts/ModalVerificationPhoneNumber";
import { ModalSaveGenderVerification } from "layouts/ModalSaveGenderVerification";

AOS.init({
  duration: 1000,
});

function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <GlobalStyle />
        <ViewportProvider>
          <ModalVerificationPhoneNumber />
          <ModalSaveGenderVerification />
          <MainRoutes />
          <ToastContainer autoClose={8000} theme="colored" />
        </ViewportProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
