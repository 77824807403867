import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: process.env.REACT_APP_APPLICATION_ID_DATADOG!,
    clientToken: process.env.REACT_APP_CLIENT_TOKEN_DATADOG!,
    site: process.env.REACT_APP_URL_SITE_DATADOG,
    service: process.env.REACT_APP_APPLICATION_ID_DATADOG,
    env: process.env.REACT_APP_NODE_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: Number(process.env.REACT_APP_SESSION_REPLAY_RATE || 0),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackingConsent: localStorage.getItem("dd-tracking-consent") === "accept" ? "granted" : "not-granted",
    defaultPrivacyLevel: 'mask-user-input',
});

function addEventListenerOnAcceptCookiesButton() {
    const btnAcceptCookies = document.getElementById('adopt-accept-all-button');
    if (btnAcceptCookies) {
        btnAcceptCookies?.addEventListener('click', function () {
            localStorage.setItem("dd-tracking-consent", "accept");
            datadogRum.setTrackingConsent('granted');
        })
    }
}

function addEventListenerOnRejectCookiesButton() {
    const btnRejectCookies = document.getElementById('adopt-reject-all-button');
    if (btnRejectCookies) {
        btnRejectCookies?.addEventListener('click', function () {
            localStorage.setItem("dd-tracking-consent", "not-accept");
            datadogRum.setTrackingConsent('not-granted');
        });
    }
}

(function addEventsOnAdoptWidget() {
    const intervalOfAdoptConsentButtons = setInterval(() => {
        const adoptConsentElement = document.getElementById('adopt-controller-button');
        if (adoptConsentElement) {
            adoptConsentElement?.addEventListener('click', function () {
                addEventListenerOnAcceptCookiesButton();
                addEventListenerOnRejectCookiesButton();
            });
        }
    });
    const intervalOfAcceptAndRejectButtons = setInterval(() => {
        addEventListenerOnAcceptCookiesButton();
        addEventListenerOnRejectCookiesButton();
    });
    setTimeout(() => {
        clearInterval(intervalOfAdoptConsentButtons);
        clearInterval(intervalOfAcceptAndRejectButtons);
    }, 2000);
})();
