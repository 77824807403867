import { formatPaymentsFromGetPendingCheckout } from "hooks/useCheckout/formatPaymentsFromGetPendingCheckout";
import { IResponseGetPendingCheckout } from "hooks/useCheckout/types";
import { toast } from "react-toastify";
import { baseURL, getApiVersionForCheckout } from "services";
import { CartItems } from "services/cart/types";
import { GetEventInLocalStorage } from "services/eventTicket/helper";
import {
  getLocalCommissioner,
  isCommissioner,
} from "../../helpers/comissioner";
import {
  GatewayType,
  GeneratePixType,
  ICard,
  ICardResponse,
  ICheckoutPro,
  ICheckoutProResponse,
  ICreatePixForMultiplePayment,
  ICreditCardRequest,
  IGetPublicKey,
  IInstallment,
  IPartialTransactionResponse,
  IPaymentGatewayTransactionRequest,
  IPaymentGatewayTransactionResponse,
  IPaymentStatusCreditCardResponse,
  IPendingMultipleTransactionResponse,
  IReimburseMultiplePaymentsRequest,
  IReimburseMultiplePaymentsResponse,
  ImpPix
} from "./interface";
/*
const createPix = async (
  {
    client,
    franchiseId,
    cartItems,
    gateway = GatewayType.mercadoPago,
    firstName,
    lastName,
    email,
    typeDocument,
    document,
    documentNFCE,
    preferenceId = '',
    SeatsHoldToken,
    protection,
  }: ImpPix,
  captcha: string,
): Promise<GeneratePixType> => {
  try {
    let gatewayCheckout = GatewayCheckout.NotSet
    switch (gateway) {
      case GatewayType.mercadoPago:
        gatewayCheckout = GatewayCheckout.MercadoPago
        break
      case GatewayType.MP_Pacaembu:
        gatewayCheckout = GatewayCheckout.MPPacaembu
        break
    }

    const payloadObject: PayloadCheckout = {
      client: {
        id: client.id,
        name: client.name,
        email: client.email,
      },
      paymentMethodInfo: {
        pixInfo: {
          email,
          firstName,
          lastName,
          type: typeDocument,
          value: document,
        },
      },
      cartItems: cartItems.map(item => {
        return {
          id: item.id,
          quantity: item.quantity,
          noInterest: item.noInterest,
          eventId: item.eventId,
          eventName: item.sectionName,
          sectionType: item.sectionType,
          sectionName: item.sectionName,
          taxTotal: item.taxTotal,
          taxAppTotal: item.taxAppTotal,
          ticketName: item.ticketName,
          lotId: item.lotId,
          ticketId: item.ticketId,
          ticketLotId: item.lotId,
          ticketLotValue: item.value,
          ticketValue: item.value,
          lotTax: Math.round(item.taxTotal * 100) / 100,
          lotProcessingFeeAdd: item.LotProcessingFeeAdd,
          lotProcessingFeeValue: item.LotProcessingFeeValue,
          cpfs: item.cpfs,
          coordinates: item.coordinates,
        }
      }),
      paymentType: PaymentTypeCheckout.Pix,
      gateway: gatewayCheckout,
      nfeDocument: documentNFCE || null,
      appVersion: null,
      seatsHoldToken: SeatsHoldToken,
      franchiseId: franchiseId,
      sellerId: isCommissioner() ? getLocalCommissioner()?.id : '',
      preferenceId: preferenceId,
      sessionId: localStorage.getItem('sessionId')?.replace(/"/g, ''),
      protection,
    }
    const userToken = getUserLocalStorage()
    const response = await axios.post('https://checkout.stg.q2ingressos.com.br/checkout/v1/', payloadObject, {
      headers: { captcha, Authorization: `Bearer ${userToken?.token}` },
    })
    return response.data
  } catch (error: any) {
    toast.error(error?.response, { delay: 10 })
    throw new Error(error?.response)
  }
}
*/

const createPix = async ({
  client,
  franchiseId,
  cartItems,
  gateway = GatewayType.mercadoPago,
  firstName,
  lastName,
  email,
  typeDocument,
  document,
  documentNFCE,
  preferenceId = "",
  SeatsHoldToken,
  protection
}: ImpPix, captcha: string): Promise<GeneratePixType> => {
  try {
    const currentVersion = getApiVersionForCheckout();

    let payloadObject = {
      Sale: {
        client,
        cartItems,
        gateway,
        franchiseId,
        sellerId: isCommissioner() ? getLocalCommissioner()?.id : "",
        documentNFCE: documentNFCE || null,
        preferenceId,
        SeatsHoldToken
      },
      MercadoPagoData: {
        payer: {
          email,
          firstName,
          lastName,
          identification: {
            type: typeDocument,
            number: document,
          },
        },
      },
      protection,
      sessionId: localStorage.getItem("sessionId")?.replace(/"/g, ''),
    };
    const { data: response } = await baseURL.post(
      `api/mercadopago/pix/${currentVersion}create`,
      payloadObject,
      { headers: { captcha } }
    );
    if (response?.status === "error") {
      toast.error(response?.Message.replace("<br/>", ""), { delay: 10 });
      throw new Error(response?.Message);
    }
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response);
  }
};


export const ticketTransaction = async (data: ICard, captcha: string) => {
  try {
    const currentVersion = getApiVersionForCheckout()

    data.sessionId = localStorage.getItem('sessionId')?.replace(/"/g, '')

    const response = await baseURL.post(`api/${currentVersion}website/checkout`, data, { headers: { captcha } })
    return response?.data
  } catch (error: any) {
    throw error?.response
  }
}

const createCard = async (data: ICard, captcha: string): Promise<ICardResponse> => {
  const currentVersion = getApiVersionForCheckout();
  const { useTicketLots } = GetEventInLocalStorage();
  data.sessionId = localStorage.getItem("sessionId")?.replace(/"/g, '');

  try {
    const response = !isCommissioner()
      ? await baseURL.post(`api/${currentVersion}website/newfinishpayment`, data, { headers: { captcha } })
      : await baseURL.post(`api/CommissionersLandingPage/newfinishpayment${useTicketLots ? 'V2' : ''}`, data, {
        headers: { captcha },
      })
    return response.data
  } catch (error: any) {
    throw error?.response
  }
}

const paymentStatusCreditCard = async (paymentId: string, noInterest: string, franchiseId: string, gateway: string): Promise<IPaymentStatusCreditCardResponse> => {
  try {
    const { data: response } = await baseURL.get(`api/v1/mercadopago/payments/${paymentId}`, {
      headers: {
        'X-NoInterest': noInterest,
        'X-FranchiseId': franchiseId,
        'X-Gateway': gateway,
      },
    })

    return response
  } catch (error: any) {
    throw error?.response
  }
}

const paymentStatus = async (paymentId: string) => {
  try {
    const response = await baseURL.get(`/api/newtickettransaction/${paymentId}/payment-status`)
    return response?.data?.PaymentStatus
  } catch (error: any) {
    throw error?.response
  }
}

const pendingMultipleTransaction = async (): Promise<IResponseGetPendingCheckout | null> => {
  try {
    const { data: { Result } } = await baseURL.get<IPendingMultipleTransactionResponse>('api/PendingMultipleTransaction');
    if (Result.length <= 0 || Result[0].paymentsDetail.length <= 0) return null
    const responseData = Result[0];
    return {
      cart: responseData.cart.cart,
      event: responseData.eventData,
      payments: formatPaymentsFromGetPendingCheckout(responseData.paymentsDetail),
      sessionID: responseData.cart.sessionID,
      transactionUID: responseData.multiplePaymentUID
    };
  } catch (error: any) {
    console.error({ error })
    throw error?.response;
  }
};

const getInstallments = async (
  eventId: number,
  totalValue: number
): Promise<IInstallment[]> => {
  try {
    const response = await baseURL.get(`api/socialEvent/${eventId}/getInstallments?value=${totalValue}`)
    return response?.data
  } catch (error: any) {
    throw error?.response
  }
}

const payCreditCardMultiple = async (data: ICreditCardRequest, captcha: string): Promise<IPartialTransactionResponse> => {
  const currentVersion = getApiVersionForCheckout();
  data.sessionId = localStorage.getItem("sessionId")?.replace(/"/g, '');

  try {
    const response = await baseURL.post(`api/${currentVersion}website/newfinishpayment`,
      data,
      { headers: { captcha } }
    );
    return response.data;
  } catch (error: any) {
    throw error?.response;
  }
};

const createPixForMultiplePayment = async ({
  client,
  franchiseId,
  cartItems,
  gateway = GatewayType.mercadoPago,
  firstName,
  lastName,
  email,
  typeDocument,
  document,
  documentNFCE,
  preferenceId = "",
  SeatsHoldToken,
  protection,
  isMultiplePayment,
  totalTicketValue,
  multiplePaymentUid,
  multiplePaymentValue
}: ICreatePixForMultiplePayment, captcha: string): Promise<GeneratePixType> => {
  try {
    const currentVersion = getApiVersionForCheckout();

    let payloadObject = {
      Sale: {
        client,
        cartItems,
        gateway,
        franchiseId,
        sellerId: isCommissioner() ? getLocalCommissioner()?.id : "",
        documentNFCE: documentNFCE || null,
        preferenceId,
        SeatsHoldToken
      },
      MercadoPagoData: {
        payer: {
          email,
          firstName,
          lastName,
          identification: {
            type: typeDocument,
            number: document,
          },
        },
      },
      protection,
      isMultiplePayment,
      totalTicketValue,
      multiplePaymentUid,
      multiplePaymentValue,
      sessionId: localStorage.getItem("sessionId")?.replace(/"/g, '')
    };
    const { data: response } = await baseURL.post(
      `api/mercadopago/pix/${currentVersion}create`,
      payloadObject,
      { headers: { captcha } }
    );
    if (response?.status === "error") {
      toast.error(response?.Message.replace("<br/>", ""), { delay: 10 });
      throw new Error(response?.Message);
    }
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response);
  }
};

const checkoutProMP = async (data: ICheckoutPro): Promise<ICheckoutProResponse> => {
  try {
    const cartItemsWithNewParams = data.cartItems.map((cartItem: CartItems) => {
      return {
        ...cartItem,
        ticketLotId: cartItem.lotId,
        ticketValue: Math.round(cartItem.value * 100) / 100,
        lotTax: Math.round(cartItem.taxTotal * 100) / 100,
      }
    })
    const response = await baseURL.post(`api/checkoutPro/create`, {
      ...data,
      sessionId: localStorage.getItem("sessionId")?.replace(/"/g, ''),
      cartItems: cartItemsWithNewParams
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response)
  }
}

const cancelPreferenceId = async ({ preferenceId }: { preferenceId: string }): Promise<void> => {
  try {
    await baseURL.delete(`api/checkoutPro/${preferenceId}`)
  } catch (error: any) {
    throw new Error(error?.response)
  }
}

const getPublicKey = async ({ paymentGateway, franchiseId, isNoInterest }: IGetPublicKey): Promise<string> => {
  try {
    const response = await baseURL.post('api/website/GetPaymentGatewaysKeys', {
      FranchiseId: franchiseId,
      IsNoInterest: Boolean(isNoInterest),
      PaymentGateway: paymentGateway,
    })
    return response?.data?.paymentGateways?.publicKeys?.[0]?.ApiPublicKey
  } catch (error: any) {
    throw new Error(error?.response)
  }
}

const reimburseMultiplePayments = async ({ transactionUID }: IReimburseMultiplePaymentsRequest): Promise<IReimburseMultiplePaymentsResponse> => {
  try {
    const { data } = await baseURL.post(`api/reimburseService/reimburseMultiplePayments/${transactionUID}`);
    return data;
  } catch (error: any) {
    throw error?.response;
  }
};

const getStatusPaymentGatewayTransaction = async ({ paymentId }: IPaymentGatewayTransactionRequest): Promise<IPaymentGatewayTransactionResponse> => {
  try {
    const { data } = await baseURL.get<IPaymentGatewayTransactionResponse>(`api/v1/paymentGatewayTransaction/${paymentId}`);
    return data;
  } catch (error: any) {
    throw error?.response;
  }
}

export {
  cancelPreferenceId,
  checkoutProMP,
  createCard,
  createPix,
  createPixForMultiplePayment,
  getInstallments,
  getPublicKey,
  getStatusPaymentGatewayTransaction,
  payCreditCardMultiple,
  paymentStatus,
  paymentStatusCreditCard,
  pendingMultipleTransaction,
  reimburseMultiplePayments
};

