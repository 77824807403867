import { IPaymentsDetail } from "services/payments/interface";
import { AdapterState } from "./adapterState";
import { ChosenMethod, PaymentsData } from "./types";

export const formatPaymentsFromGetPendingCheckout = (payments: IPaymentsDetail[]): PaymentsData[] => {
    const formattedPayments: PaymentsData[] = [];
    payments.forEach((payment, index) => {
        formattedPayments.push({
            id: `${Date.now().toString()}_${index}`,
            chosenMethod: ChosenMethod.CREDIT_CARD,
            state: AdapterState(payment.status),
            paymentId: payment.paymentCode,
            totalValueToPay: payment.partialOrderValue,
            creditCardData: {
                cardName: payment.cardHolderName,
                cardNumber: payment.cardNumber,
                installments: `${payment.installments}`,
                installmentValue: `${payment.paymentValue / payment.installments}`,
                isMultiplePayment: true,
                partialPaymentValue: payment.partialOrderValue,
            },
        })
    })
    return formattedPayments;
}