import { createContext, useEffect, useState } from "react";
import {
  postUpdateMyAccount,
  registerRequest,
  signInRequest,
} from "services/user/requests";
import { RegisterRequestProp, SignInCredentials } from "services/user/types";
import { baseURL } from "../../services";

import { AuthContextData, AuthProviderProps, User, Token } from "./types";

import {
  getUserLocalStorage,
  setUserLocalStorage,
  clearUserLocalStorage,
} from "./util";

import jwt from "jwt-decode";
import { datadogRum } from "@datadog/browser-rum";
export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();
  const [tokenAuth, setToken] = useState<string>("");

  const isAuthenticated = () => {
    return getUserLocalStorage() ? true : false;
  };

  const renewEndpoint = async (token: any, newToken?: string) => {
    return newToken
      ? baseURL.get("api/v2/authentication", {
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        })
      : baseURL.get("api/v2/authentication", token);
  };

  const renew = async (newToken?: string): Promise<User | undefined> => {
    const token = getUserLocalStorage();
    try {
      const response = await renewEndpoint(token, newToken);
      await setUser(response?.data?.userToken?.user);
      const payload = {
        token: response?.data?.token,
      };
      await setUserLocalStorage(payload);
      setToken(payload?.token);
      return response?.data?.userToken?.user;
    } catch (error) {
      clearUserLocalStorage();
      datadogRum.clearUser();
    }
  };

  const getTokenExpData = (): Date | undefined => {
    const token = getUserLocalStorage();
    if (!token || !token?.token) return;
    try {
      const obj: Token = jwt(token?.token);

      if (!obj?.exp) {
        return;
      }

      return new Date(obj.exp * 1000);
    } catch (e: any) {
      localStorage.removeItem("u");
      return;
    }
  };

  const loadUserDataFromToken = (): User | null => {
    const token = getUserLocalStorage();

    try {
      if (token) {
        const obj: Token = jwt(token.token);
        if (obj.user) {
          setUser(obj.user);
          setToken(token.token);
          datadogRum.setUser({
            id: `${obj.user.id}`,
            name: obj.user.name,
            email: obj.user.email
        })
          return obj.user;
        }
      }

      setUser(undefined);
      setToken("");
      return null;
    } catch (e: any) {
      localStorage.removeItem("u");
      return null;
    }
  };

  useEffect(() => {
    const userObj = loadUserDataFromToken();
    const expTime = getTokenExpData();

    const timeNow = new Date();
    if ((expTime && timeNow > expTime) || userObj?.emailVerified === 0) {
      renew();
    }
    window.addEventListener("storage", loadUserDataFromToken);
    return () => {
      window.removeEventListener("storage", loadUserDataFromToken);
    };
  }, []);

  async function signIn(credentials: SignInCredentials) {
    try {
      const response = await signInRequest(credentials);
      const payload = {
        token: response.token,
      };

      setToken(payload?.token);
      setUser(response.client);
      setUserLocalStorage(payload);
      
      datadogRum.setUser({
        id: `${response.client.id}`,
        name: response.client.name,
        email: response.client.email
      });

      return response?.client;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  async function registerClient(credentials: RegisterRequestProp) {
    try {
      const response = await registerRequest(credentials);
      const payload = {
        token: response.token,
      };

      await setUser({
        ...response.client,
        // @ts-ignore
        name: response?.client?.Name,
        // @ts-ignore
        isPhoneVerified: response?.client?.ClientData?.isPhoneVerified,
      });
      await setUserLocalStorage(payload);
      setToken(payload?.token);
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  const updateMyAccount = async (userData: any): Promise<User | undefined> => {
    if (user) {
      const response = await postUpdateMyAccount(userData);

      setUser(response.client);
      datadogRum.setUser({
        id: `${response.client.id}`,
        name: response.client.name,
        email: response.client.email
      });
      setUserLocalStorage({ token: response.token });

      return response.client;
    } else {
      throw new Error("Dados do Usuário não encontrado");
    }
  };

  function logout() {
    datadogRum.clearUser();
    setUserLocalStorage(null);
    setToken("");
    window.location.href = "/login";
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        logout,
        isAuthenticated,
        token: tokenAuth,
        user,
        updateMyAccount,
        loadUserDataFromToken,
        renew,
        registerClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
