import React from "react";
import { ButtonProps } from "./interface";

export const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  type = "button",
  onClick,
  className = "",
}) => {
  return (
    <button
      type={type}
      className={`q2c-primary-button-component ${
        disabled ? "is-disabled" : ""
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
