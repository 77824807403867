export enum Breakpoint {
  'xl' = 1600,
  'lg' = 1200,
  'md' = 1024,
  'sm' = 800,
  'xs' = 576,
  'mobile' = 475,
}


export enum TicketLayouts {
  Default = 0,
  FPF = 1,
}

export enum ErrorMessageAddToCart {
  LIMIT_EXCEEDED = "É possível adicionar somente 10 ingressos por vez em seu carrinho, finalize essa compra e adquira mais ingressos em uma próxima.",
  TICKET_LIMIT_EXCEEDED = "A quantidade de ingressos selecionada não está disponível neste lote, aguarde o próximo lote ou diminua a quantidade selecionada.",
  LOT_SOLD_OUT = "Este lote está esgotado, aguarde um momento para geração do novo.",
  UNKNOW_ERROR = "Falha ao adicionar item no carrinho",
  GENERIC_ERROR = "Falha ao adicionar item no carrinho",
  DIFFERENT_EVENT = "Não é permitido a compra de ingressos de eventos diferentes",
}

export enum ErrorMessageCheckoutMP {
  LOT_SOLD_OUT = 'Este lote já esgotou, aguarde até que o próximo seja liberado.',
  UNAUTHORIZED_PAYMENT = 'Seu pagamento não foi autorizado, por favor, tente novamente. Recomendamos que tente com outro cartão ou por outro meio de pagamento.',
  GENERIC_ERROR = 'Ocorreu um erro ao processar seu pagamento, por favor, tente novamente.',
}
