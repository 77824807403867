import styled from "styled-components";
import { whatsButtonImg } from "assets/img";

type WrapperProps = {
  isEventMapPage?: boolean;
}

export const WhatsAppIcon = styled.img.attrs({
  src: whatsButtonImg,
  alt: "whatsapp",
})`
  width: 6rem;

  @media (max-width: 480px) {
    width: 5rem;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  right: 20px;
  bottom: 2rem;
  z-index: 2;

  display: ${({ isEventMapPage }) => isEventMapPage ? "none" : "block"};
`;