import { Link, useLocation } from "react-router-dom";
import { WhatsAppIcon, Wrapper } from "./styles";


export function WhatsappButton() {
  const location = useLocation();

  const IS_EVENT_MAP_PAGE = location.pathname.includes("/eventmap");

  return (
    <Wrapper isEventMapPage={IS_EVENT_MAP_PAGE}>
      <Link to="/whatsapp" aria-label="whatsapp">
        <WhatsAppIcon />
      </Link>
    </Wrapper>
  );
}
