
export async function setSessionIdLocalStorage(sessionId: string) {
  localStorage.setItem("sessionId", JSON.stringify(sessionId || ""));
}

export const getSessionId = async () => {
  const json = await localStorage.getItem("sessionId");

  if (json && JSON.parse(json) !== "") {
    return JSON.parse(json);
  }

  return undefined;
}