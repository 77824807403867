export type MenuList = {
  name: string;
  url: string;
  target: string;
};

export type MenuData = {
  title: string;
  menuList: MenuList[];
};

export const menuData: MenuData[] = [
  {
    title: "Segurança",
    menuList: [
      {
        name: "Termos de Consentimento para Produtores",
        url: "/termos-de-consentimento-para-produtores",
        target: "_self",
      },
      {
        name: "Políticas de Privacidade",
        url: "/politicas-de-privacidade",
        target: "_self",
      },
      {
        name: "Direitos dos titulares",
        url: "/direitos-dos-titulares",
        target: "_self",
      },
      {
        name: "Ingresso Reembolsável",
        url: "/ingresso-reembolsavel",
        target: "_self",
      },
      {
        name: "Termos de uso",
        url: "/termos-de-uso",
        target: "_self",
      },
    ],
  },
  {
    title: "Perguntas frequentes",
    menuList: [
      {
        name: "Acesse nosso FAQ",
        url: "/institucional#faq",
        target: "_self",
      },
      {
        name: "Informações meia entrada",
        url: "/meia-entrada",
        target: "_self",
      },
    ],
  },
  {
    title: "Institucional",
    menuList: [
      {
        name: "Sobre a empresa",
        url: "/institucional",
        target: "_blank",
      },
      {
        name: "Manual de marca",
        url: `${process.env.REACT_APP_BASE_URL}/Home/ManualMarca`,
        target: "_blank",
      },
    ],
  },
];
