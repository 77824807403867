import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  cartImg,
  cartClock,
  menuImg,
  logoutImg
} from "assets/img";
import { HeaderLinkProps, ModalProps } from "./types";

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 7.2rem;
  width: 100%;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  padding: 13px 0px;

  &.is-active-shadow {
    box-shadow: 0.8px 3.9px 2.2px rgba(0, 0, 0, 0.011),
      2px 9.3px 5.3px rgba(0, 0, 0, 0.016),
      3.8px 17.5px 10px rgba(0, 0, 0, 0.02),
      6.7px 31.3px 17.9px rgba(0, 0, 0, 0.024),
      12.5px 58.5px 33.4px rgba(0, 0, 0, 0.029),
      30px 140px 80px rgba(0, 0, 0, 0.04);
  }
`;

export const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 166.4rem;
  padding: 0 10.2rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    padding: 0 2rem;
  }
`;

export const Divide = styled.div`
  margin: 0px 32px;
  height: 100%;
  width: 1px;
  background: #fff;

  @media (max-width: 576px) {
    display: none;
  }
`

export const HeaderSection = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1 1;

  &.has-align-end{
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: center;
    justify-content: end;
    gap: 2rem;
    flex: 1 1;
  }

  @media (max-width: 576px) {
    gap: 24px;
    flex: 2 1;
  }
`;

export const MenuSection = styled.section`
  display: flex;

  @media (max-width: 800px){
    display: none;
  }
`;

export const HeaderLink = styled(Link) <HeaderLinkProps>`
  color: ${(props) => props.actualPage ? "var(--white)" : "var(--gray50)"};
  transition: color 0.3s;
  font-size: 1.6rem;
  font-weight: 600;
  margin: auto;
  &:hover{
    color: #fff;
  }
  &:not(:first-child) {
    margin-left: 3.2rem;
  }
`;

export const HeaderLinkA = styled.a<HeaderLinkProps>`
  color: ${(props) => props.actualPage ? "var(--white)" : "var(--gray50)"};
  transition: color 0.3s;
  font-size: 1.6rem;
  font-weight: 600;
  margin: auto;
  &:hover{
    color: #fff;
  }
  &:not(:first-child) {
    margin-left: 3.2rem;
  }
`;

export const OldIosFix = styled.div` 
  width: 100%;
  display: none;
`;

export const ToggleMenu = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  max-width: 3.6rem;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const MenuImage = styled.img.attrs({
  src: menuImg,
  alt: "Icone menu mobile q2l-header__toggle-menu-icon"
})`
  max-width: 100%;
`;

export const IdentificationWrapper = styled.div`
  position: relative;
`;

export const ModalSair = styled.div<ModalProps>`
  position: absolute;
  top: 4rem;
  -webkit-transform: ${props => props.open ? "translateY(0)" : "translateY(2rem)"};
  transform: ${props => props.open ? "translateY(0)" : "translateY(2rem)"};
  visibility: ${props => props.open ? "visible" : "hidden"};
  transition-duration: ${props => props.open ? ".5s" : "0"};
`;

export const ShowModal = styled.div`
  background-color: transparent;
`;

export const LogoutButtonWrapper = styled.div`
  background: var(--white);
  border-radius: 0.8rem;
  box-shadow: 0.5px 2.7px 18.9px rgb(0 0 0 / 2%), 1.3px 6.3px 30.2px rgb(0 0 0 / 3%), 2.4px 11.8px 39.3px rgb(0 0 0 / 4%), 4.3px 21.7px 48.8px rgb(0 0 0 / 4%), 8.6px 43.2px 61.5px rgb(0 0 0 / 5%), 20px 100px 84px rgb(0 0 0 / 7%);
  display: flex;
  height: 4.5rem;
  padding: 0;
  width: 17.6rem;
`;

export const LogoutButton = styled.button.attrs({
  type: "button"
})`
    align-items: center;
    display: flex;
    overflow: hidden;
    transition: -webkit-filter .3s ease;
    transition: filter .3s ease;
    transition: filter .3s ease,-webkit-filter .3s ease;
    width: 100%;
`;

export const LogoutButtonText = styled.span`
  color: var(--gray600);
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
`;

export const LogoutButtonImg = styled.img.attrs({
  src: logoutImg,
  alt: "logout image"
})`
  margin-left: 3.2rem;
  margin-right: 2.4rem;
`;



//////////////////////////////////////////////

export const CartButtonWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  @media (max-width: 474px){
    display: none;
  }
`;

export const CartButton = styled.button`
  position: relative;
`;

export const CartButtonMobile = styled.button`
  position: relative;
  @media (min-width: 475px){
    display: none;
  }
`;
export const CartImg = styled.img.attrs({
  width: 30,
  height: 30,
  src: cartImg
})`
  width: 3rem;
  max-width: fit-content;
`;

export const CartClock = styled.img.attrs({
  src: cartClock,
  width: 15,
  height: 15
})`
  position: absolute;
  width: 3rem;
  max-width: fit-content;
  right: -4px;
  top: -2px;
`;

export const ModalPaymentWrapper = styled.div`
  position: absolute;
  right: -20px;
  width: 435px;
`;