import styled from 'styled-components'
import { TotalWrapperProps } from './types'

export const Wrapper = styled.div`
  position: relative;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.102);
  border-radius: 8px;
`

export const TitleWrapper = styled.div`
  display: flex;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #005b8e;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #cecece;
`

export const ExpirationWrapper = styled.div`
  padding: 24px 0px;
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  font-weight: 400;
  color: #666666;
  padding: 8px 0px;
`

export const TotalWrapper = styled(PriceWrapper)<TotalWrapperProps>`
  border-top: 1px solid #cecece;
  font-weight: 500;
  color: #000000;
  ${props => props.inCart && `padding: 8px 0px 0px 0px;`}
`

export const Description = styled.div`
  white-space: nowrap;
`

export const Price = styled.div``

export const TicketWrapper = styled.div``

export const ItemWrapper = styled.div`
  width: 100%;
`

export const ItemTitle = styled.div`
  display: flex;
  width: 100%;
  text-align: left;

  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #292929;
  padding: 12px 0 8px 0px;
`

export const ItemPlace = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 8px 0 4px 0px;

  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #666666;
`

export const ItemData = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 4px 0 8px 0px;

  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #666666;
`

export const ItemQuantity = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 8px 0 24px 0px;

  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #292929;
`

export const ButtonWrapper = styled.div`
  padding-top: 16px;
`

export const TriangleBallon = styled.div`
  position: absolute;
  top: -10px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid white;
`

export const TextBase = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
`

export const TicketNameWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;
  max-width: 20rem;

  margin-right: 32px;

  @media (min-width: 1920px) {
    max-width: 50rem;
  }
`

export const InfoIcon = styled.img`
  margin-left: 4px;
`

export const IconButton = styled.button`
  width: 20px;
  height: 20px;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.7);
  }
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModalTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  color: #005b8e;
`

export const ModalCloseButton = styled.img`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

export const ModalText = styled(TextBase)`
  color: #292929;
  font-size: 14px;
  text-align: justify;
  margin-top: 18px;
`
