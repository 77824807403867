export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CDN_URL = process.env.REACT_APP_CDN;
export const REACT_APP_CDN_IMG = process.env.REACT_APP_CDN_IMG;
export const IBGE_URL = process.env.REACT_APP_IBGE_URL;
export const REACT_APP_MULTIADQUIRENTE_URL =
  process.env.REACT_APP_MULTIADQUIRENTE_URL;
export const REACT_APP_RECAPTCHA = process.env.REACT_APP_RECAPTCHA;

export const SALE_APROVED = 3;
export const SALE_WAITING = 1;
export const SALE_ANALYSIS = 4;
export const SALE_RECUSED = 7;

export const USER_NOT_FOUND = "Nenhum usuário encontrado.";
export const DEFAULT_ERROR_MESSAGE =
  "Ocorreu um erro inesperado, tente novamente mais tarde";
