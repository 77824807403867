import ExpirationCartTimer from 'components/ExpirationCartTimer'
import {
  ButtonWrapper,
  Description,
  ExpirationWrapper,
  ItemData,
  ItemPlace,
  ItemQuantity,
  ItemTitle,
  ItemWrapper,
  ModalCloseButton,
  ModalHeader,
  ModalText,
  ModalTitle,
  Price,
  PriceWrapper,
  TicketWrapper,
  TitleWrapper,
  TotalWrapper,
  TriangleBallon,
  Wrapper,
} from './style'

import Modal from 'components/NewModal'
import { TransformToMoney } from 'helpers'
import { capitalizeFirstLetter } from 'helpers/text'
import { useCart } from 'hooks/useCart/useCart'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { closeModalImg, trashBlueImg } from 'assets/img'
import Button from 'components/NewButton'
import ExpiredCartError from '../../Errors/ExpiredCartError'
import { toast } from 'react-toastify'

const ModalPaymentDetail = () => {

  const { cart, total, totalTax, subTotal, quantityItemInCart, totalProcessingFee, clearCart, removeFromCart, incompleteTables } = useCart()

  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const disableCartButton = incompleteTables.length > 0

  const formatDateEvent = (date: string) => {
    if (!date) return null

    const dateString = new Date(date)
    const week = new Intl.DateTimeFormat('pt-BR', { weekday: 'short' }).format(dateString).toLocaleUpperCase().replace('.', '')
    const day = new Intl.DateTimeFormat('pt-BR', { day: '2-digit' }).format(dateString).toLocaleUpperCase()
    const month = new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(dateString).toLocaleUpperCase()

    const weekFormatted = capitalizeFirstLetter(week)
    const monthFormatted = capitalizeFirstLetter(month)

    return `${weekFormatted}, ${day} de ${monthFormatted}`
  }

  const inCart = window.location.pathname == '/carrinho'

 const handleClearCart = () => {
   try {
     const HAS_SEATS_TABLE = cart?.items?.some(item => item.coordinates?.some(coordinate => coordinate.SeatsTable))

     if (HAS_SEATS_TABLE) {
       cart?.items?.forEach(item => {
         item.coordinates?.forEach(coordinate => {
           removeFromCart({
             lotId: item?.lotId,
             quantity: item?.quantity,
             controlledPlaceId: coordinate?.controlledPlaceId,
             holdToken: cart?.holdToken,
           })
         })
       })
     } else {
       clearCart()
     }
   } catch (error: any) {
     if (error instanceof ExpiredCartError) {
       toast.error('Erro ao limpar o carrinho.')
       clearCart()
       navigate('/')
     }
   } finally {
     toast.success('Carrinho limpo com sucesso!')
   }
 }

 return (
   <Wrapper>
     <TriangleBallon />
     <TitleWrapper>Resumo da compra</TitleWrapper>

     <ExpirationWrapper>
       <ExpirationCartTimer size={18} />
     </ExpirationWrapper>

     <TicketWrapper>
       <ItemWrapper>
         <ItemTitle>{cart?.items?.[0]?.eventName}</ItemTitle>
         <ItemPlace>
           {cart?.items?.[0]?.eventCity}/{cart?.items?.[0]?.eventState}
         </ItemPlace>
         <ItemData>{formatDateEvent(cart?.items?.[0]?.eventDate)}</ItemData>
         <ItemQuantity>{quantityItemInCart} x ingressos</ItemQuantity>
       </ItemWrapper>
     </TicketWrapper>

     <PriceWrapper>
       <Description>Subtotal:</Description>
       <Price>{TransformToMoney('BRL', subTotal || 0)}</Price>
     </PriceWrapper>

     <PriceWrapper>
       <Description>Taxa de conveniência:</Description>
       <Price>{TransformToMoney('BRL', totalTax || 0)}</Price>
     </PriceWrapper>

     <TotalWrapper inCart>
       <Description>Total:</Description>
       <Price>{TransformToMoney('BRL', Number(total - totalProcessingFee))}</Price>
     </TotalWrapper>

     {!inCart && (
       <ButtonWrapper>
         <Button height="40px" onClick={() => navigate('/carrinho')} disabled={disableCartButton}>
           Ir para o carrinho
         </Button>
       </ButtonWrapper>
     )}

     <ButtonWrapper>
       <Button height="40px" icon={trashBlueImg} outline={true} onClick={handleClearCart}>
         Limpar carrinho
       </Button>
     </ButtonWrapper>

     <Modal
       isOpen={isModalOpen}
       onClose={() => {
         setIsModalOpen(false)
       }}
     >
       <>
         <ModalHeader>
           <ModalTitle>Taxa de Processamento</ModalTitle>
           <ModalCloseButton src={closeModalImg} onClick={() => setIsModalOpen(false)} />
         </ModalHeader>

         <ModalText>
           A taxa de processamento representa o valor que será transferido ao serviço de transação online para tornar possível a
           realização do pagamento.
         </ModalText>
       </>
     </Modal>
   </Wrapper>
 )
}

export default ModalPaymentDetail
