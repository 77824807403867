import * as Yup from "yup";
import YupPassword from "yup-password";
import { cpf } from "cpf-cnpj-validator";
import moment from "moment";

YupPassword(Yup);

export const registerFormSchema = Yup.object().shape({
  name: Yup.string()
    .required("O nome é obrigatório")
    .test("is-email", "Não é permitido email nesse campo", (value) => {
      return !value || !Yup.string().email().isValidSync(value);
    })
    .test("surname", "É obrigatório colocar nome e sobrenome", (value) => {
      const nomeSobrenome =
        /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,19}\b/gi;

      return value == undefined ? true : nomeSobrenome.test(value);
    }),
  phoneNumber: Yup.string().required("O telefone é obrigatório"),
  email: Yup.string()
    .required("O e-mail é obrigatório")
    .email("Informe um e-mail válido"),
  password: Yup.string()
    .required("A senha é obrigatória")
    .password()
    .minUppercase(1, "Precisa ter ao menos 1 caractere maiúsculo")
    .min(8, "Precisa ter no mínimo 8 caracteres")
    .minLowercase(1, "Precisa ter ao menos 1 caractere minúsculo")
    .minNumbers(1, "Precisa ter ao menos 1 número")
    .minSymbols(1, "Precisa ter ao menos 1 caractere especial"),
  confirmPassword: Yup.string()
    .required("Repita a senha")
    .oneOf([Yup.ref("password"), null], "A senha não confere"),
  cpf: Yup.string()
    .required("O CPF é obrigatório")
    .test("Validate CPF", "Informe um CPF válido", (value) =>
      cpf.isValid(String(value))
    ),
  birthDate: Yup.string()
    .required("A data é obrigatória")
    .test("valid birthdate", "Data de aniversário inválida", (value) => {
      if (!value) return false;
      let date = moment(value, "DD/MM/YYYY");
      let format = /\d{2}\/\d{2}\/\d{4}/.test(value);
      return date.isValid() && date < moment() && format;
    }),
  gender: Yup.object().shape({
    label: Yup.string().required("O gênero é obrigatório"),
    value: Yup.number().required("O gênero é obrigatório"),
  }),
  state: Yup.object().shape({
    label: Yup.string().required("O estado é obrigatório"),
    value: Yup.number().required("O estado é obrigatório"),
  }),
  city: Yup.object().shape({
    label: Yup.string().required("A cidade é obrigatório"),
    value: Yup.number().required("A cidade é obrigatório"),
  }),
  captcha: Yup.string().required(),
});
