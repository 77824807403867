import { MyShops, Transactions } from 'services/myShops/types'
import { CalculateProps, PromoTicketsProps } from './types'
import { Coordinate } from 'services/cart/types'

const sortTicketsByBuyDate = (tickets: MyShops[]) => {
  tickets.sort((a: MyShops, b: MyShops) => {
    let aDate = a.sales[0]?.date ? new Date(a.sales[0]?.date).getTime() : 0
    let bDate = b.sales[0]?.date ? new Date(b.sales[0]?.date).getTime() : 0
    return bDate - aDate
  })

  return tickets
}

const sortTransactionsByBuyDate = (transactions: Transactions[]) => {
  transactions.sort((a: Transactions, b: Transactions) => {
    let aDate = a.date ? new Date(a.date).getTime() : 0
    let bDate = b.date ? new Date(b.date).getTime() : 0
    return bDate - aDate
  })

  return transactions
}

const hashIdToNumber = (ticketHash: string) => {
  let retStr = ''
  try {
    for (let i = 0; i < ticketHash.length; i++) {
      let val = ticketHash.charCodeAt(i) - 48
      if (val < 10) retStr += '0' + val
      else retStr += val
    }
  } catch (ex) {
    retStr = ''
  }
  return retStr
}

const calculateTicketValueWithPromoTicket = ({ ticketValue, promoTicketQuantity }: PromoTicketsProps) => {
  const valueWithPromoTicket = ticketValue * promoTicketQuantity!

  return valueWithPromoTicket
}

const calculateTicketValue = ({ ticketValue, promoTicket, promoTicketQuantity }: CalculateProps) => {
  let value = ticketValue

  //Calculate ticket value with promo ticket
  if (!!promoTicket)
    value = calculateTicketValueWithPromoTicket({
      ticketValue,
      promoTicket,
      promoTicketQuantity,
    })

  return Number(value.toFixed(2))
}

const formatSeatBadgeNameWithoutTable = (value: string): string => {
  const parts = value.split('-')

  const seatNumber = parts.pop()
  const rowLetter = parts.pop()
  const sector = parts.join(' ')

  const formattedString = `${sector} - Fileira ${rowLetter} | Assento ${seatNumber}`

  return formattedString
}

const formatSeatBadgeNameWithTable = (coordinate: Coordinate, sectionName: string) => {
  const sectionNameRegex = new RegExp(sectionName, 'g')

  const aliasWithoutSectionName = coordinate?.alias!.replace(sectionNameRegex, '')
  const cleanAlias = aliasWithoutSectionName.replace(/^[\s\W_]+/, '').trim()

  return cleanAlias
}

export {
  sortTicketsByBuyDate,
  sortTransactionsByBuyDate,
  hashIdToNumber,
  calculateTicketValue,
  formatSeatBadgeNameWithoutTable,
  formatSeatBadgeNameWithTable,
}
