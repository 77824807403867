export enum Names {
  searchEvent = 'search'
}

export enum Options {
  FALSE = 0,
  TRUE = 1
}

export enum Gender {
  NotRegistered = 0,
  Male = 1,
  Female = 2,
  Other = 3,
  PreferNotToSay = 4
}
