import { UserToken } from "./types";

export function setUserLocalStorage(token: UserToken | null) {
  localStorage.setItem("u", JSON.stringify(token));
}

export function getUserLocalStorage() {
  const json = localStorage.getItem("u");

  if (!json) return null;
  try{
    const user = JSON.parse(json);
    return user ?? null;
  } catch(e:any){
    localStorage.removeItem("u");
    return null;
  }
}

export const clearUserLocalStorage = () => {
  localStorage.removeItem("u");
};
