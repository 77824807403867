const removeDuplicatedText = (text: string) => {
  const textItems = text?.split(" ");
  const removeDuplicated = new Set([...textItems]);
  const transformToArray = Array.from(removeDuplicated);

  const transformToOriginalText = transformToArray.join(" ");
  return transformToOriginalText;
};

const reduceText = (text: string, amountCharacters: number) => {
  let textVector = text.trim().substring(0, amountCharacters).split(" ");

  if (text.length >= amountCharacters) {
    return `${textVector.join(" ")}...`;
  }

  return text;
};

const capitalizeFirstLetter = (text: string) => {
  const capitalize =
    text?.charAt(0)?.toUpperCase() + text?.slice(1).toLocaleLowerCase();
  return capitalize;
};

const capitalizeWord = (message: string) => {
  const arrayWord = message.split(" ");

  const messageFormatted = arrayWord
    .map((word) => {
      if (!word) return null;
      return word[0]?.toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");

  return messageFormatted;
};

const firstLetterOfWordInUpperCase = (text: string) => {
  const arrayText = text?.split(" ");
  const textCapitalized = arrayText?.map((word) =>
    capitalizeFirstLetter(word.toLowerCase())
  );
  return textCapitalized?.join(" ");
};

const normalizeText = (text: string): string => {
  const textNormalize = text?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
  return textNormalize.toLowerCase();
};

export {
  removeDuplicatedText,
  reduceText,
  firstLetterOfWordInUpperCase,
  capitalizeFirstLetter,
  capitalizeWord,
  normalizeText,
};
