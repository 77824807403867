import { FormEvent, KeyboardEvent } from "react";
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  phoneNumberMask,
  unMask,
  cpfCnpjMask,
} from "./masks";

export const handleKeyUpMask = (
  event: FormEvent<HTMLInputElement>,
  mask: string | undefined
) => {
  if (mask == "phoneNumber") {
    phoneNumberMask(event);
  } else if (mask == "cpf") {
    cpfMask(event);
  } else if (mask == "date") {
    dateMask(event);
  } else if (mask == "cnpj") {
    cnpjMask(event);
  } else if (mask == "cep") {
    cepMask(event);
  } else if (mask == "cpfCnpj") {
    cpfCnpjMask(event);
  } else {
    unMask(event);
  }
};

export const handleKeyDownMask = (
  event: KeyboardEvent<HTMLInputElement>,
  mask: string | undefined
) => {
  if (mask == "email" && event.key == " ") {
    event.preventDefault();
  }
};


export const testRNE = (value: string): Boolean => {
  const RNERegex = /^[A-Za-z](\d{6})-[A-Za-z]$|^[A-Za-z](\d{6})[A-Za-z]$/;
  const isValidRNE = RNERegex.test(value);
  return isValidRNE;
};
export const testPassport = (value: string): Boolean => {
  const PassportRegex = /^[a-zA-Z0-9]{9}$/;
  const isValidPassport = PassportRegex.test(value);
  return isValidPassport;
};
